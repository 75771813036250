import { StyleSheet } from "react-native";

const HomeStyle = StyleSheet.create({
	Container: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "flex-start",
		backgroundColor: "#EDF1FA",
		height: "100%",
		width: "100%",
		position: "relative",
	},
	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		gap: 0,
		// marginBottom: 20,
		width: "100%",
		// borderWidth: 1,
		height: 60,
		padding: 10,
	},
	profile: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "center",
		gap: 5,
		// width: "100%",
		// borderWidth: 1,
	},
	title: {
		fontSize: 13,
		color: "#FFF",
		fontFamily: "Poppins-ExtraLight",
	},
	titleDex: {
		marginTop: -5,
		fontSize: 17,
		fontWeight: "bold",
		color: "#FFF",
		fontFamily: "Poppins-Regular",
	},
	body: {
		display: "flex",
		flexDirection: "column",
		// alignItems: "center",
		// justifyContent: "flex-start",
		gap: 0,

		width: "100%",
		height: "100%",
		position: "relative",
		zIndex: -1,
	},
	bodyHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		gap: 0,
		// marginBottom: 20,
		width: "100%",
	},
	balanceRef: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "space-between",
		gap: 5,
		backgroundColor: "#FFF",
		width: "100%",
		height: 80,
		borderRadius: 10,
		padding: 10,
	},
	balanceRefText: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		gap: 5,
		padding: 10,
		height: "100%",
		// width: "100%",
		// height: 80,
		// borderRadius: 10,
	},
	ButtonContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		gap: 5,
		padding: 10,
	},
	bodyMenu: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		flexWrap: "wrap",
		gap: 10,
		// marginBottom: 20,
		width: "100%",
		height: 90,
	},
	meuItem: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		gap: 5,
		backgroundColor: "#FFF",
		width: "25%",
		height: "100%",
		borderRadius: 10,
		padding: 10,
		// marginTop: 20,
	},
	meuItemIcon: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		gap: 0,
		width: 55,
		height: 50,
		borderRadius: 10,
		backgroundColor: "#EDF1FA",
	},
	meuItemText: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		gap: 0,
		width: "100%",
		// height: 34,
		// borderRadius: 34,
		// backgroundColor: "#FFF",
	},
	bodyHistory: {
		display: "flex",
		flexDirection: "column",
		// alignItems: "center",
		// justifyContent: "center",
		gap: 10,
		// marginTop: 20,
		marginBottom: 20,
		width: "100%",
	},
	bodyHistoryHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start",
		gap: 0,
		marginTop: 20,
		// marginBottom: 10,
		width: "100%",
		padding: 10,
		// borderWidth: 1,
		height: 40,
	},
	Transactions: {
		display: "flex",
		flexDirection: "column",
		gap: 2,
		// marginTop: 20,
		marginBottom: 20,
		width: "100%",
		height: "100%",
		padding: 10,
	},
	transaction: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		backgroundColor: "#FFF",
		gap: 0,
		marginBottom: 10,
		width: "100%",
		// borderWidth: 1,
		// borderColor: "#d9d9d9",
		borderRadius: 5,
		height: 60,
		padding: 10,
	},
	transactionLeftSide: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start",
		gap: 10,
	},
	transactionIcon: {
		width: 40,
		height: 40,
		borderRadius: 10,
		backgroundColor: "#FFF",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		gap: 0,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
	},
});

export default HomeStyle;
