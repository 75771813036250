import PayPalIcon from "../../assets/icons/Paypal.png";
import AirtimeIcon from "../../assets/icons/airtime.png";
import BankIcon from "../../assets/icons/bank.png";
import BillsIcon from "../../assets/icons/bills.png";
import TransfertIcon from "../../assets/icons/transfert.png";
import WoyofalIcon from "../../assets/icons/woyofal.png";
import rapidoIcon from "../../assets/icons/rapido.png";
import SenelecIcon from "../../assets/icons/senelec.png";
import ucadIcon from "../../assets/icons/ucad.png";
import UvssIcon from "../../assets/icons/uvss.png";

const menusItems = [
	{
		id: 1,
		title: "Transfert",
		icon: TransfertIcon,
	},
	{
		id: 2,
		title: "Airtime",
		icon: AirtimeIcon,
	},
	{
		id: 3,
		title: "PayPal",
		icon: PayPalIcon,
	},
];

const altMenusItems = [
	{
		id: 1,
		title: "Woyofal",
		icon: WoyofalIcon,
	},
	{
		id: 2,
		title: "Senelec",
		icon: SenelecIcon,
	},
	{
		id: 3,
		title: "Rapido",
		icon: rapidoIcon,
	},
	{
		id: 4,
		title: "Ucad",
		icon: ucadIcon,
	},
];

export { menusItems, altMenusItems };
