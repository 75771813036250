import { StyleSheet } from "react-native";

const onboardingStyle = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#76D5FD",
    height: "100%",
    width: "100%",
    position: "relative",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 0,
    marginBottom: 20,
    width: "90%",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#fff",
    fontFamily: "Poppins-Bold",
    // lineHeight: 16
  },
  titleDex: {
    marginTop: -10,
    fontSize: 37,
    fontWeight: "bold",
    color: "#fff",
    fontFamily: "Poppins-Bold",
  },
  text: {
    marginTop: 20,
    fontSize: 18,
    color: "#fff",
    fontFamily: "Poppins-ExtraLight",
    // fontStyle: 'light',
    textAlign: "center",
    lineHeight: 20,
  },
  dots: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
    marginBottom: 20,
    width: "90%",
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: "#fff",
  },
  dotActive: {
    width: 28,
    height: 10,
    borderRadius: 10,
    backgroundColor: "#1B97F1",
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // gap: 10,
    marginBottom: 20,
    width: "90%",
    position: "absolute",
    bottom: 20,
  },
  button: {
    marginTop: 20,
    width: 150,
    height: 50,
    borderRadius: 10,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    color: "#fff",
    fontSize: 18,
    // fontWeight: 'bold',
    fontFamily: "Inter-Regular",
  },
});

export default onboardingStyle;
