import React, { useState } from "react";
import { Text, View, Image, TouchableOpacity } from "react-native";
import DropDownPicker from "react-native-dropdown-picker";
import { HelperText, TextInput, Button } from "react-native-paper";

import CIcon from "../../assets/img/CI.png";
import GIcon from "../../assets/img/gn.png";
import SnIncon from "../../assets/img/senegal.png";
import Keypad from "../../components/keypad";
import { checkNumber } from "../../helpers";
import AuthLayout from "../../layout/auth.layout";
import AuthStyle from "../../styles/auth.style";

const countries = [
  {
    label: "Senegal",
    value: "221",
    icon: () => <Image source={SnIncon} style={{ width: 30, height: 30 }} />,
  },
  {
    label: "Côte d'Ivoire",
    value: "225",
    icon: () => <Image source={CIcon} style={{ width: 30, height: 30 }} />,
  },
  {
    label: "Guinée",
    value: "224",
    icon: () => <Image source={GIcon} style={{ width: 30, height: 30 }} />,
  },
];

export const Auth = ({ navigation }) => {
  const [country, setCountry] = React.useState("221");
  const [open, setOpen] = React.useState(false);
  const [number, setNumber] = React.useState({
    value: "",
    error: false,
    message: "",
  });

  const handleContinue = () => {
    //console.log("handleContinue", number.value);
    const { isValid, message } = checkNumber(number.value);
    if (!isValid) {
      setNumber({ ...number, error: true, message });
      return;
    }
    setNumber({ ...number, error: false, message: "" });
    navigation.navigate("Login", { nextScreen: "Main" });
  };
  return (
    <AuthLayout>
      <View style={{ ...AuthStyle.header }}>
        <View style={{ ...AuthStyle.stepConatiner }}>
          <Text style={{ ...AuthStyle.stepText }}>01</Text>
        </View>
      </View>
      <View style={{ ...AuthStyle.formContainer }}>
        <View style={{ ...AuthStyle.formGroup, zIndex: 100 }}>
          <DropDownPicker
            language="fr"
            items={countries}
            open={open}
            value={country}
            setOpen={setOpen}
            setValue={setCountry}
            style={{
              borderColor: "#76D5FD",
            }}
            dropDownContainerStyle={{
              borderColor: "#76D5FD",
              zIndex: 1000,
            }}
          />
        </View>
        <View style={{ ...AuthStyle.formGroup }}>
          <TextInput
            label="Numéro de téléphone"
            value={number.value}
            onChangeText={(text) => {
              const validationResult = checkNumber(text);
              setNumber({
                ...number,
                value: text,
                error: !validationResult.isValid,
              });
            }}
            mode="outlined"
            style={{
              borderColor: "#76D5FD",
              width: "100%",
              minWidth: "100%",
            }}
            theme={{
              colors: {
                primary: "#76D5FD",
                underlineColor: "transparent",
              },
            }}
            keyboardType="numeric"
            left={<TextInput.Affix text={`+${country}`} />}
          />
          <HelperText type="error" visible={number.error}>
            {number.message}
          </HelperText>
        </View>

        <View style={{ ...AuthStyle.formGroup }}>
          <Button
            mode="contained"
            onPress={() => {
              handleContinue();
            }}
            buttonColor="#54CAFD"
            textColor="#FFF"
            style={{ borderRadius: 5, width: 150 }}
          >
            Continuer
          </Button>
        </View>
      </View>
    </AuthLayout>
  );
};

export const AuthOTPVerification = ({ route, navigation }) => {
  const { nextScreen } = route.params;
  const [verificationCode, setVerificationCode] = useState("");

  const addDigit = (digit) => {
    if (verificationCode.length < 6) {
      setVerificationCode(verificationCode + digit);
    }
  };

  const removeDigit = () => {
    if (verificationCode.length > 0) {
      setVerificationCode(verificationCode.slice(0, -1));
    }
  };

  const navigationFunction = () => {
    if (nextScreen && nextScreen === "SignupForm") {
      navigation.navigate("SignupForm");
    } else if (nextScreen && nextScreen === "Main") {
      navigation.reset({
        index: 0,
        routes: [{ name: "Main" }],
      });
    }
  };

  // console.log("verificationCode", nextScreen);
  return (
    <Keypad
      props={{
        title: "Code de vérification",
        length: 6,
        verificationCode,
        addDigit,
        removeDigit,
        nextScreen: navigationFunction,
      }}
    />
  );
};

export const Login = ({ navigation }) => {
  const [verificationCode, setVerificationCode] = useState("");

  const addDigit = (digit) => {
    if (verificationCode.length < 4) {
      setVerificationCode(verificationCode + digit);
    }
  };

  const removeDigit = () => {
    if (verificationCode.length > 0) {
      setVerificationCode(verificationCode.slice(0, -1));
    }
  };

  const navigationFunction = () => {
    navigation.navigate("AuthOTPVerification", { nextScreen: "Main" });
  };

  // console.log("Code Pin", verificationCode);
  return (
    <Keypad
      props={{
        title: "Votre code PIN",
        length: 4,
        verificationCode,
        addDigit,
        removeDigit,
        nextScreen: navigationFunction,
      }}
    />
  );
};
export const SignupForm = ({ navigation }) => {
  const [firstname, setFirstname] = useState({
    value: "",
    error: false,
    message: "",
  });
  const [lastname, setLastname] = useState({
    value: "",
    error: false,
    message: "",
  });
  const [referralCode, setReferralCode] = useState({
    value: "",
    error: false,
    message: "",
  });
  const [pinCode, setPinCode] = useState({
    value: "",
    error: false,
    message: "",
  });
  const [confirmPinCode, setConfirmPinCode] = useState({
    value: "",
    error: false,
    message: "",
  });
  const [tosAccepted, setTosAccepted] = useState(false);

  const handleContinue = () => {
    // console.log(
    //   "handleContinue",
    //   firstname,
    //   lastname,
    //   referralCode,
    //   pinCode,
    //   confirmPinCode,
    //   tosAccepted,
    // );
    navigation.reset({
      index: 0,
      routes: [{ name: "Main" }],
    });
  };

  return (
    <AuthLayout>
      <View style={{ ...AuthStyle.header }}>
        <View
          style={{ ...AuthStyle.stepConatiner, width: "auto", padding: 10 }}
        >
          <Text style={{ ...AuthStyle.stepText }}>Inscription</Text>
        </View>
      </View>
      <View style={{ ...AuthStyle.formContainer, marginTop: -5 }}>
        <View style={{ ...AuthStyle.formGroup }}>
          <TextInput
            label="Prénom"
            value={firstname.value}
            onChangeText={(text) => {
              setFirstname({
                ...firstname,
                value: text,
              });
            }}
            mode="outlined"
            style={{
              borderColor: "#76D5FD",
              width: "100%",
              minWidth: "100%",
            }}
            theme={{
              colors: {
                primary: "#76D5FD",
                underlineColor: "transparent",
              },
            }}
          />
        </View>
        <View style={{ ...AuthStyle.formGroup }}>
          <TextInput
            label="Nom"
            value={lastname.value}
            onChangeText={(text) => {
              setLastname({
                ...lastname,
                value: text,
              });
            }}
            mode="outlined"
            style={{
              borderColor: "#76D5FD",
              width: "100%",
              minWidth: "100%",
            }}
            theme={{
              colors: {
                primary: "#76D5FD",
                underlineColor: "transparent",
              },
            }}
          />
        </View>
        <View style={{ ...AuthStyle.formGroup }}>
          <TextInput
            label="Code de parrainage (facultatif)"
            value={referralCode.value}
            onChangeText={(text) => {
              setReferralCode({
                ...referralCode,
                value: text,
              });
            }}
            mode="outlined"
            style={{
              borderColor: "#76D5FD",
              width: "100%",
              minWidth: "100%",
            }}
            theme={{
              colors: {
                primary: "#76D5FD",
                underlineColor: "transparent",
              },
            }}
          />
        </View>
        <View style={{ ...AuthStyle.formGroup }}>
          <TextInput
            label="Code PIN 4 chiffres"
            value={pinCode.value}
            onChangeText={(text) => {
              setPinCode({
                ...pinCode,
                value: text,
              });
            }}
            mode="outlined"
            style={{
              borderColor: "#76D5FD",
              width: "100%",
              minWidth: "100%",
            }}
            secureTextEntry
            keyboardType="numeric"
            maxLength={4}
            theme={{
              colors: {
                primary: "#76D5FD",
                underlineColor: "transparent",
              },
            }}
          />
        </View>
        <View style={{ ...AuthStyle.formGroup }}>
          <TextInput
            label="Confirmer le code PIN"
            value={confirmPinCode.value}
            onChangeText={(text) => {
              setConfirmPinCode({
                ...confirmPinCode,
                value: text,
              });
            }}
            mode="outlined"
            secureTextEntry
            keyboardType="numeric"
            maxLength={4}
            style={{
              borderColor: "#76D5FD",
              width: "100%",
              minWidth: "100%",
            }}
            theme={{
              colors: {
                primary: "#76D5FD",
                underlineColor: "transparent",
              },
            }}
          />
        </View>

        <View style={{ ...AuthStyle.formGroup }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              minWidth: "100%",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setTosAccepted(!tosAccepted);
              }}
              style={{
                width: 20,
                height: 20,
                borderRadius: 5,
                borderWidth: 1,
                borderColor: "#76D5FD",
                marginRight: 10,
              }}
            >
              {tosAccepted && (
                <View
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 5,
                    backgroundColor: "#76D5FD",
                  }}
                />
              )}
            </TouchableOpacity>
            <Text
              style={{
                fontFamily: "Poppins-Regular",
                fontSize: 11,
                color: "#76D5FD",
              }}
            >
              J'accepte les conditions générales d'utilisation
            </Text>
          </View>
        </View>
        <View style={{ ...AuthStyle.formGroup }}>
          <Button
            mode="contained"
            onPress={() => {
              handleContinue();
            }}
            buttonColor="#54CAFD"
            textColor="#FFF"
            style={{ borderRadius: 5, width: 150 }}
          >
            S'inscrire
          </Button>
        </View>
      </View>
    </AuthLayout>
  );
};
