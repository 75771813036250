export default {
    EN: {
        PLACEHOLDER: 'Select an item',
        SEARCH_PLACEHOLDER: 'Type something...',
        SELECTED_ITEMS_COUNT_TEXT: {
            1: 'An item has been selected',
            n: '{count} items have been selected'
        },
        NOTHING_TO_SHOW: 'There\'s nothing to show!'
    },
    AR: {
        PLACEHOLDER: 'حدد عنصر',
        SEARCH_PLACEHOLDER: 'يبحث...',
        SELECTED_ITEMS_COUNT_TEXT: 'تم اختیار {count} عنصر',
        NOTHING_TO_SHOW: 'لا يوجد شيء لعرضه!'
    },
    FA: {
        PLACEHOLDER: 'آیتمی انتخاب کنید',
        SEARCH_PLACEHOLDER: 'چیزی تایپ کنید...',
        SELECTED_ITEMS_COUNT_TEXT: {
            1: 'یک آیتم انتخاب شده است',
            n: '{count} آیتم انتخاب شده است'
        },
        NOTHING_TO_SHOW: 'چیزی برای نمایش وجود ندارد!'
    },
    TR: {
        PLACEHOLDER: 'Bir seçenek seçin',
        SEARCH_PLACEHOLDER: 'Arama...',
        SELECTED_ITEMS_COUNT_TEXT: {
            1: 'Bir öğe seçildi',
            n: '{count} öğe seçildi'
        },
        NOTHING_TO_SHOW: 'Gösterecek hiçbir şey yok!'
    },
    RU: {
        PLACEHOLDER: 'Выберите элемент',
        SEARCH_PLACEHOLDER: 'Введите текст...',
        SELECTED_ITEMS_COUNT_TEXT: 'Выбрано элементов: {count}',
        NOTHING_TO_SHOW: 'Элементы не найдены!'
    },
    ES: {
        PLACEHOLDER: 'Selecciona un elemento',
        SEARCH_PLACEHOLDER: 'Escribe algo...',
        SELECTED_ITEMS_COUNT_TEXT: 'Se han seleccionado {count} elemento(s)',
        NOTHING_TO_SHOW: '¡No hay nada que mostrar!'
    },
    ID: {
        PLACEHOLDER: 'Pilih item',
        SEARCH_PLACEHOLDER: 'Ketik sesuatu...',
        SELECTED_ITEMS_COUNT_TEXT: '{count} item telah dipilih',
        NOTHING_TO_SHOW: 'Tidak ada yang bisa ditampilkan!'
    },
    IT: {
        PLACEHOLDER: 'Seleziona un elemento',
        SEARCH_PLACEHOLDER: 'Digita qualcosa...',
        SELECTED_ITEMS_COUNT_TEXT: {
            1: 'Un elemento è stato selezionato',
            n: '{count} elementi sono stati selezionati'
        },
        NOTHING_TO_SHOW: 'Non c\'è nulla da mostrare!'
    },
    PT: {
        PLACEHOLDER: 'Selecione um item',
        SEARCH_PLACEHOLDER: 'Faça sua busca...',
        SELECTED_ITEMS_COUNT_TEXT: {
            1: 'Um item selecionado',
            n: '{count} alguns itens selecionados'
        },
        NOTHING_TO_SHOW: 'Nada a ser mostrado!'
    },
    FR: {
        PLACEHOLDER: 'Sélectionnez un élément',
        SEARCH_PLACEHOLDER: 'Tapez quelque chose...',
        SELECTED_ITEMS_COUNT_TEXT: {
            1: 'Un élément a été sélectionné',
            n: '{count} éléments ont été sélectionnés'
        },
        NOTHING_TO_SHOW: 'Il n\'y a rien à montrer!'
    },
}
