import React from "react";
import { Text, View, Image, TouchableOpacity } from "react-native";

import HomeImg from "../../assets/img/home.png";
import Onboarding2Img from "../../assets/img/onboarding2.png";
import Onboarding3Img from "../../assets/img/onboarding3.png";
import OnboardingLayout from "../../layout/onboarding.layout";
import onboardingStyle from "../../styles/onboarding.style";

export const OnboardingHome = ({ navigation }) => {
  return (
    <OnboardingLayout>
      <View style={{ ...onboardingStyle.header }}>
        <Image source={HomeImg} style={{ width: 300, height: 300 }} />
        <Text style={{ ...onboardingStyle.title }}>Bienvenue sur 🤗 </Text>
        <Text style={{ ...onboardingStyle.titleDex }}>DEXCHANGE</Text>
        <Text style={{ ...onboardingStyle.text }}>
          Échangez, vos mobile money money, achetez du crédit et payez vos
          factures en toute simplicité
        </Text>
      </View>
      <View style={{ ...onboardingStyle.dots }}>
        <View style={{ ...onboardingStyle.dotActive }} />
        <View style={{ ...onboardingStyle.dot }} />
        <View style={{ ...onboardingStyle.dot }} />
      </View>
      <View style={{ ...onboardingStyle.buttons, justifyContent: "flex-end" }}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("Onboarding2");
          }}
          style={{ ...onboardingStyle.button, backgroundColor: "#1B97F1" }}
        >
          <Text style={{ ...onboardingStyle.buttonText }}>Suivant</Text>
        </TouchableOpacity>
      </View>
    </OnboardingLayout>
  );
};

export const Onboarding2 = ({ navigation }) => {
  return (
    <OnboardingLayout>
      <View style={{ ...onboardingStyle.header }}>
        <Image source={Onboarding2Img} style={{ width: 300, height: 300 }} />
        <Text style={{ ...onboardingStyle.title }}>
          Simple, rapide et sécurisé{" "}
        </Text>
        <Text style={{ ...onboardingStyle.text }}>
          Faites vos transactions en toute sécurité et en un temps record avec
          une interface simple !
        </Text>
      </View>
      <View style={{ ...onboardingStyle.dots }}>
        <View style={{ ...onboardingStyle.dot }} />
        <View style={{ ...onboardingStyle.dotActive }} />
        <View style={{ ...onboardingStyle.dot }} />
      </View>
      <View style={{ ...onboardingStyle.buttons }}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("Onboarding");
          }}
          style={{
            ...onboardingStyle.button,
            backgroundColor: "none",
            border: "1px solid #407BFF",
          }}
        >
          <Text style={{ ...onboardingStyle.buttonText, color: "#FFF" }}>
            Précédent
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("Onboarding3");
          }}
          style={{ ...onboardingStyle.button, backgroundColor: "#1B97F1" }}
        >
          <Text style={{ ...onboardingStyle.buttonText }}>Suivant</Text>
        </TouchableOpacity>
      </View>
    </OnboardingLayout>
  );
};

export const Onboarding3 = ({ navigation }) => {
  return (
    <OnboardingLayout>
      <View style={{ ...onboardingStyle.header }}>
        <Image source={Onboarding3Img} style={{ width: 300, height: 300 }} />
        <Text style={{ ...onboardingStyle.title }}>24/7 Support</Text>
        <Text style={{ ...onboardingStyle.text }}>
          Un support disponible disponible 24/7 pour tous vos soucis !
        </Text>
      </View>
      <View style={{ ...onboardingStyle.dots }}>
        <View style={{ ...onboardingStyle.dot }} />
        <View style={{ ...onboardingStyle.dot }} />
        <View style={{ ...onboardingStyle.dotActive }} />
      </View>
      <View style={{ ...onboardingStyle.buttons }}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("Onboarding2");
          }}
          style={{
            ...onboardingStyle.button,
            backgroundColor: "none",
            border: "1px solid #407BFF",
          }}
        >
          <Text style={{ ...onboardingStyle.buttonText, color: "#FFF" }}>
            Précédent
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("Auth");
          }}
          style={{ ...onboardingStyle.button, backgroundColor: "#1B97F1" }}
        >
          <Text style={{ ...onboardingStyle.buttonText }}>Suivant</Text>
        </TouchableOpacity>
      </View>
    </OnboardingLayout>
  );
};
