import { View, Image, TouchableOpacity } from "react-native";
import { Text, Avatar, Button } from "react-native-paper";
import ExchangeIcon from "../../assets/icons/exchange.png";
import AirtimeIcon from "../../assets/icons/airtime.png";
import BankIcon from "../../assets/icons/bank.png";
import BillsIcon from "../../assets/icons/bills.png";
import PayPalIcon from "../../assets/icons/Paypal.png";
import HomeStyle from "../styles/home.style";

const TransactionComponent = ({ navigation, props }) => {
    return (
        <TouchableOpacity style={{ ...HomeStyle.transaction }} onPress={
            () => navigation.navigate("TransactionDetail", {
                sender: props.sender,
                receiver: props.receiver,
                amount: props.amount,
                type: props.type,
                datetime: props.datetime,
                status: props.status
            })
        }>
            <View style={{ ...HomeStyle.transactionLeftSide }}>
            <View style={{ ...HomeStyle.transactionIcon }}>
                <Image source={
                    props.type == "Airtime" ? AirtimeIcon :
                    props.type == "Bank" ? BankIcon :
                    props.type == "Bills" ? BillsIcon :
                    props.type == "Paypal" ? PayPalIcon :
                    ExchangeIcon
                } style={{ width: 25, height: 25 }} />
            </View>
            <View style={{ ...HomeStyle.transactionText }}> 
                <Text style={{fontFamily: 'Poppins-Bold'}}>{props.type === "Airtime" ? "Achat de crédit" : `${props.sender} vers ${props.receiver}`}</Text>
                <Text style={{fontFamily: 'Poppins-ExtraLight', fontSize: 10}}>{props.datetime}</Text>
            </View>
            </View>
            <View style={{ ...HomeStyle.transactionRightSide }}>
            <Text style={{fontFamily: 'Poppins-Bold', color: "#54CAFD", fontSize: 22}}> {props.amount} <Text style={{fontFamily: 'Poppins-Bold', color: "#54CAFD", fontSize: 12}}>FCFA</Text></Text>
            </View>
        </TouchableOpacity>
    );
};

export default TransactionComponent;