import { View, Image, TouchableOpacity, Text } from "react-native";

import HomeStyle from "../styles/home.style";

const Menu = ({ props, navigation, customStyle }) => {
	return (
		<TouchableOpacity style={{ ...HomeStyle.meuItem, customStyle }}>
			<View style={{ ...HomeStyle.meuItemIcon }}>
				<Image source={props.icon} style={{ width: 25, height: 25 }} />
			</View>
			<View style={{ ...HomeStyle.meuItemText }}>
				<Text
					style={{
						...HomeStyle.title,
						color: "#54CAFD",
						fontFamily: "Poppins-ExtraLight",
						fontSize: 12,
					}}
				>
					{props.title}
				</Text>
			</View>
		</TouchableOpacity>
	);
};

const MenuAlt = ({ props, navigation, customStyle }) => {
	return (
		<TouchableOpacity style={{ ...HomeStyle.meuItem, width: "20%" }}>
			<View style={{ ...HomeStyle.meuItemIcon }}>
				<Image source={props.icon} style={{ width: 25, height: 25 }} />
			</View>
			<View style={{ ...HomeStyle.meuItemText }}>
				<Text
					style={{
						...HomeStyle.title,
						color: "#54CAFD",
						fontFamily: "Poppins-ExtraLight",
						fontSize: 12,
					}}
				>
					{props.title}
				</Text>
			</View>
		</TouchableOpacity>
	);
};

export { Menu, MenuAlt };
