import { MaterialCommunityIcons, AntDesign } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as Font from "expo-font";
import React, { useEffect, useState } from "react";

import Settings from "./src/screens/Settings";
import {
	OnboardingHome,
	Onboarding2,
	Onboarding3,
} from "./src/screens/onboarding/Home";
import {
	Auth,
	AuthOTPVerification,
	SignupForm,
	Login,
} from "./src/ui/auth/Auth";
import Home from "./src/ui/home/Home";
import TransactionDetail from "./src/screens/TransactionDetail";

/* 
'Poppins-Bold': require('../assets/fonts/Poppins/Poppins-Bold.ttf'),
      'Poppins-Regular': require('../assets/fonts/Poppins/Poppins-Regular.ttf'),
      'Poppins-ExtraLight': require('../assets/fonts/Poppins/Poppins-ExtraLight.ttf'),
      'Inter-Regular': require('../assets/fonts/Inter/Inter-Regular.ttf'),
*/

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function HomeStack() {
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="DEXCHANGE TRANSFERT"
				component={Home}
				options={{ headerShown: false }}
			/>
		</Stack.Navigator>
	);
}

function MainTabNavigator() {
	return (
		<Tab.Navigator>
			<Tab.Screen
				name="Accueil"
				component={HomeStack}
				options={{
					headerShown: false,
					headerTitle: "DEXCHANGE TRANSFERT",
					tabBarIcon: ({ color, size }) => (
						<MaterialCommunityIcons name="home" color={color} size={size} />
					),
				}}
			/>

			<Tab.Screen
				name="Transfert"
				component={Home}
				options={{
					headerShown: false,
					tabBarIcon: ({ color, size }) => (
						<MaterialCommunityIcons name="send" color={color} size={size} />
					),
				}}
			/>
			<Tab.Screen
				name="Historique"
				component={Home}
				options={{
					headerShown: false,
					tabBarIcon: ({ color, size }) => (
						<MaterialCommunityIcons name="history" color={color} size={size} />
					),
				}}
			/>
			<Tab.Screen
				name="Paramétres"
				component={Settings}
				options={{
					headerShown: false,
					tabBarIcon: ({ color, size }) => (
						<AntDesign name="setting" color={color} size={size} />
					),
				}}
			/>
		</Tab.Navigator>
	);
}

function App() {
	const [fontsLoaded, setFontsLoaded] = useState(false);
	useEffect(() => {
		// Load custom fonts when the app starts
		async function loadCustomFonts() {
			try {
				await Font.loadAsync({
					"Poppins-Bold": require("./assets/fonts/Poppins/Poppins-Bold.ttf"),
					"Poppins-Regular": require("./assets/fonts/Poppins/Poppins-Regular.ttf"),
					"Poppins-ExtraBold": require("./assets/fonts/Poppins/Poppins-ExtraBold.ttf"),
					"Poppins-ExtraLight": require("./assets/fonts/Poppins/Poppins-ExtraLight.ttf"),
					"Inter-Regular": require("./assets/fonts/Inter/Inter-Regular.ttf"),
				});
				setFontsLoaded(true);
			} catch (error) {
				console.error("Error loading custom fonts:", error);
			}
		}

		loadCustomFonts();
	}, []);

	if (!fontsLoaded) {
		return null;
	}

	return (
		<NavigationContainer>
			<Stack.Navigator initialRouteName="Main">
				<Stack.Screen
					name="Onboarding"
					component={OnboardingHome}
					options={{ headerShown: false, title: "DEXCHANGE TRANSFERT" }}
				/>
				<Stack.Screen
					name="Onboarding2"
					component={Onboarding2}
					options={{ headerShown: false, title: "DEXCHANGE TRANSFERT" }}
				/>
				<Stack.Screen
					name="Onboarding3"
					component={Onboarding3}
					options={{ headerShown: false, title: "DEXCHANGE TRANSFERT" }}
				/>
				<Stack.Screen
					name="Auth"
					component={Auth}
					options={{
						headerShown: true,
						headerBackTitle: "Accueil",
						title: "Authentification",
					}}
				/>
				<Stack.Screen
					name="AuthOTPVerification"
					component={AuthOTPVerification}
					options={{ headerShown: true, headerBackTitle: "", title: "" }}
				/>
				<Stack.Screen
					name="SignupForm"
					component={SignupForm}
					options={{ headerShown: true, headerBackTitle: "", title: "" }}
				/>
				<Stack.Screen
					name="Login"
					component={Login}
					options={{ headerShown: true, headerBackTitle: "", title: "" }}
				/>
				<Stack.Screen
					name="Main"
					component={MainTabNavigator}
					options={{
						headerShown: false,
						title: "DEXCHANGE TRANSFERT",
					}}
				/>

				<Stack.Screen
					name="TransactionDetail"
					component={TransactionDetail}
					options={{ headerShown: true, title: "Détail du transfert" }}
				/>
			</Stack.Navigator>
		</NavigationContainer>
	);
}

export default App;
