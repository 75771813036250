import { AntDesign, FontAwesome } from "@expo/vector-icons";
import React from "react";
import { SafeAreaView, Text, View, TouchableOpacity } from "react-native";

import AuthStyle from "../styles/auth.style";

const Keypad = ({ props }) => {
  return (
    <SafeAreaView style={AuthStyle.Container}>
      <View style={AuthStyle.formContainer}>
        <View
          style={{
            ...AuthStyle.header,
            marginTop: 10,
            justifyContent: "center",
            alignItems: "center",
            height: "15%",
          }}
        >
          <Text
            style={{
              fontFamily: "Poppins-ExtraLight",
              fontWeight: "100",
              textTransform: "uppercase",
              fontSize: 24,
            }}
          >
            {props.title}
          </Text>
        </View>
        <View style={AuthStyle.padContent}>
          {Array.from({ length: props.length }).map((_, index) => (
            <View key={index} style={AuthStyle.padCircle}>
              {index < props.verificationCode.length && (
                <Text style={AuthStyle.padDigit}>
                  {props.verificationCode[index]}
                </Text>
              )}
            </View>
          ))}
        </View>
        <View style={AuthStyle.padBody}>
          {Array.from({ length: 9 })
            .reduce((rows, _, digit) => {
              if (digit % 3 === 0) {
                rows.push([]);
              }
              rows[rows.length - 1].push(digit);
              return rows;
            }, [])
            .map((row, rowIndex) => (
              <View
                key={rowIndex}
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {row.map((digit) => (
                  <TouchableOpacity
                    key={digit}
                    style={{ ...AuthStyle.padButton }}
                    onPress={() => props.addDigit(String(digit + 1))}
                  >
                    <Text style={AuthStyle.padButtonText}>{digit + 1}</Text>
                  </TouchableOpacity>
                ))}
              </View>
            ))}
        </View>
        <View style={{ ...AuthStyle.padFooter, width: "90%", marginTop: 10 }}>
          <TouchableOpacity
            style={{
              ...AuthStyle.padButton,
              backgroundColor: "#1B97F1",
              borderWidth: 0,
            }}
            onPress={props.removeDigit}
          >
            <FontAwesome name="remove" size={24} color="white" />
          </TouchableOpacity>

          <TouchableOpacity
            style={{ ...AuthStyle.padButton }}
            onPress={() => props.addDigit("0")}
          >
            <Text style={AuthStyle.padButtonText}>0</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={{
              ...AuthStyle.padButton,
              backgroundColor: "#1B97F1",
              borderWidth: 0,
            }}
            onPress={() => {
              props.nextScreen();
            }}
          >
            <AntDesign name="check" size={24} color="white" />
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default Keypad;
