import { StyleSheet, Text, View, SafeAreaView } from "react-native";
import React from "react";
import { AntDesign } from "@expo/vector-icons";
import { Button } from "react-native-paper";

const TransactionStyle = StyleSheet.create({
	Container: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "flex-start",
		backgroundColor: "#FFF",
		height: "100%",
		width: "100%",
		position: "relative",
	},
	header: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
		gap: 10,
		// marginBottom: 20,
		width: "100%",
		// borderWidth: 1,
		height: "auto",
		padding: 10,
	},
	body: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "flex-start",
		gap: 0,
		marginBottom: 20,
		width: "100%",
	},
	bodyHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		gap: 0,
		marginBottom: 20,
		width: "100%",
		padding: 10,
	},
	bodyContent: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		gap: 0,
		marginBottom: 20,
		width: "100%",
		padding: 10,
	},
	bodyContentLeftSide: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		gap: 10,
		marginBottom: 20,
		width: "50%",
		padding: 10,
	},
	bodyContentRightSide: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		justifyContent: "flex-start",
		gap: 10,
		marginBottom: 20,
		width: "50%",
		padding: 10,
	},
	footer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "flex-start",
		gap: 0,
		// marginBottom: 20,
		width: "100%",
		padding: 10,
	},
	IDTRANSAC: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		gap: 0,
		marginBottom: 20,
		width: "100%",
		padding: 10,
	},
});

export default function TransactionDetail({ navigation, route }) {
	const { sender, receiver, amount, type, datetime, status } = route.params;
	return (
		<SafeAreaView style={{ ...TransactionStyle.Container }}>
			<View style={{ ...TransactionStyle.header }}>
				{status == "success" ? (
					<AntDesign name="checkcircle" size={70} color="#54CAFD" />
				) : (
					<AntDesign name="closecircle" size={70} color="#FF0000" />
				)}
				{status == "success" ? (
					<Text
						style={{
							fontFamily: "Poppins-Bold",
							fontSize: 18,
							color: "#54CAFD",
						}}
					>
						Transaction réussie
					</Text>
				) : (
					<Text
						style={{ fontFamily: "Poppins-Bold", fontSize: 18, color: "red" }}
					>
						Transaction échouée
					</Text>
				)}
				{/* 
            <AntDesign name="checkcircle" size={100} color="#54CAFD" />
            <Text style={{fontFamily: 'Poppins-Bold', fontSize: 22, color:"#54CAFD"}}>Transaction réussie</Text> */}
			</View>
			<View style={{ ...TransactionStyle.body }}>
				{/* <View style={{ ...TransactionStyle.bodyHeader }}>
					<Text
						style={{ fontFamily: "Poppins-Bold", fontSize: 12, color: "#000" }}
					>
						Détails de la transaction
					</Text>
				</View> */}
				<View style={{ ...TransactionStyle.bodyContent }}>
					<View style={{ ...TransactionStyle.bodyContentLeftSide }}>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							Montant
						</Text>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							Type
						</Text>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							Date
						</Text>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							Expéditeur
						</Text>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							Destinataire
						</Text>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							Numero Expéditeur
						</Text>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							Numero Destinataire
						</Text>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							Reference
						</Text>
					</View>
					<View style={{ ...TransactionStyle.bodyContentRightSide }}>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							{amount} FCFA
						</Text>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							{type}
						</Text>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							{datetime}
						</Text>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							{sender}
						</Text>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							{receiver}
						</Text>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							777460452
						</Text>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							777460452
						</Text>
						<Text
							style={{
								fontFamily: "Poppins-Bold",
								fontSize: 14,
								color: "#000",
							}}
						>
							DEXDIDDHHDBD229292
						</Text>
					</View>
				</View>
				<View style={{ justifyContent: "center", alignItems: "center" }}>
					<Button
						mode="contained"
						buttonColor="#54CAFD"
						textColor="#FFF"
						style={{ borderRadius: 10, height: 40 }}
						onPress={() => navigation.navigate("Transfert")}
					>
						RECLAMATION
					</Button>
				</View>
			</View>
		</SafeAreaView>
	);
}
