const exemplesTransactions = [
  {
    id: 1,
    type: "Transfert",
    datetime: "12-12-2020 12:00",
    amount: 10000,
    status: "success",
    sender: "OM",
    receiver: "Wave",
  },
  {
    id: 2,
    type: "Paypal",
    datetime: "12-12-2020 12:00",
    amount: 2344,
    status: "failed",
    sender: "PayPal",
    receiver: "Wave",
  },
  {
    id: 3,
    type: "Airtime",
    datetime: "12-12-2020 12:00",
    amount: 2000,
    status: "success",
    sender: "Free Money",
    receiver: "Orange",
  },
  {
    id: 4,
    type: "Transfert",
    datetime: "12-12-2020 12:00",
    amount: 10000,
    status: "failed",
    sender: "OM",
    receiver: "Wave",
  },
  {
    id: 5,
    type: "Paypal",
    datetime: "12-12-2020 12:00",
    amount: 10000,
    status: "success",
    sender: "PayPal",
    receiver: "Wave",
  },
  {
    id: 6,
    type: "Airtime",
    datetime: "12-12-2020 12:00",
    amount: 10000,
    status: "success",
    sender: "Wave",
    receiver: "Free",
  },
  {
    id: 7,
    type: "Transfert",
    datetime: "12-12-2020 12:00",
    amount: 10000,
    status: "success",
    sender: "OM",
    receiver: "Wave",
  }
];

export { exemplesTransactions };
