export default {
    WHITE: '#fff',
    BLACK: '#000',
    ALTO: '#dfdfdf',
    GREY: '#808080',
    EBONY_CLAY: '#292d3e',
    HEATHER: '#bfc7d5',
    LYNCH: '#697098',
    SHARK: '#242526',
    SHUTTLE_GREY: '#565E67'
};