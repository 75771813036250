import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { View } from "react-native";
import { Text, Avatar, Button } from "react-native-paper";
import HomeStyle from "../../styles/home.style";

const Header = ({ name }) => {
	return (
		<>
			<View style={{ ...HomeStyle.header }}>
				<View style={{ ...HomeStyle.profile }}>
					<View style={{ ...HomeStyle.Avatar }}>
						<Avatar.Text
							size={40}
							label={name
								.split(" ")
								.map((n) => n[0])
								.join("")
								.toUpperCase()}
						/>
					</View>
					<View style={{ ...HomeStyle.profileText }}>
						<Text style={{ ...HomeStyle.title }}>Hello 👋, </Text>
						<Text style={{ ...HomeStyle.titleDex }}>{name}</Text>
					</View>
				</View>
				<View style={{ ...HomeStyle.Notifications }}>
					<Ionicons name="notifications" size={24} color="#FFF" />
				</View>
			</View>
		</>
	);
};

export default Header;
