// CHECK IF NUMBER IS VALID
export const checkNumber = (number) => {
    // try{
    //     parseInt(number);
    // } catch(e){
    const regex = new RegExp(/^[0-9]{9}$/);
    if(regex.test(number)){
        return {isValid: true, message: ''};
    }
    return {isValid: false, message: 'Numéro invalide'};
}

