import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { View, SafeAreaView, ScrollView, FlatList } from "react-native";
import { Text, Avatar, Button } from "react-native-paper";
import ExchangeIcon from "../../../assets/icons/exchange.png";
import { Menu, MenuAlt } from "../../components/homeMenu";
import HomeStyle from "../../styles/home.style";
import { menusItems, altMenusItems } from "../../utils/MenuItems";
import { exemplesTransactions } from "../../utils/Sample";
import TransactionComponent from "../../components/transaction";
import Header from "./Header";

const Home = ({ navigation }) => {
	const [name] = React.useState("DENVER");
	const [ScrollViewRef, setScrollViewRef] = React.useState(null);
	return (
		<SafeAreaView style={{ ...HomeStyle.Container }}>
			{/* HEADER */}
			<View
				style={{
					height: 200,
					width: "100%",
					backgroundColor: "#54CAFD",
					padding: 10,
					paddingTop: 0,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "flex-start",
					marginBottom: 20,
				}}
			>
				<Header name={name} />
				<View style={{ ...HomeStyle.balanceRef, marginTop: 10 }}>
					<View style={{ ...HomeStyle.balanceRefText }}>
						<Text
							style={{
								...HomeStyle.title,
								color: "#54CAFD",
								fontFamily: "Poppins-Bold",
								fontSize: 12,
							}}
						>
							Solde parainnage
						</Text>
						<Text
							style={{
								...HomeStyle.titleDex,
								color: "#54CAFD",
								fontSize: 20,
								fontFamily: "Poppins-ExtraBold",
							}}
						>
							FCFA 0
						</Text>
					</View>
					<View style={{ ...HomeStyle.ButtonContainer }}>
						<Button
							mode="contained"
							buttonColor="#54CAFD"
							textColor="#FFF"
							style={{ borderRadius: 10, height: 40 }}
							onPress={() => navigation.navigate("Transfert")}
						>
							RETRAIT
						</Button>
					</View>
				</View>
				<View
					style={{
						...HomeStyle.bodyMenu,
						backgroundColor: "#FFF",
						marginTop: 10,
						marginBottom: 10,
						position: "relative",
						borderRadius: 10,
					}}
				>
					{menusItems.map((item, index) => (
						<Menu key={index} props={item} navigation={navigation} />
					))}
				</View>
			</View>

			<ScrollView style={{ ...HomeStyle.body, padding: 10, marginTop: 20 }}>
				{/* BODY MENU */}
				<View
					style={{
						backgroundColor: "#FFF",
						width: "100%",
						height: "auto",
						marginTop: 20,
						borderRadius: 10,
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						padding: 10,
						// paddingBottom: 20,
						// paddingTop: 20,
					}}
				>
					<Text style={{ ...HomeStyle.title, color: "#000" }}>
						Factures et Educations
					</Text>
					<View
						style={{
							...HomeStyle.bodyMenu,
							justifyContent: "flex-start",
							alignItems: "flex-start",
							backgroundColor: "#FFF",
							flexWrap: "wrap",
							position: "relative",
							borderRadius: 10,
							gap: 5,
						}}
					>
						{altMenusItems.map((item, index) => (
							<MenuAlt key={index} props={item} navigation={navigation} />
						))}
					</View>
				</View>

				{/* TRANSACTION HISTORY */}
				<ScrollView style={{ ...HomeStyle.bodyHistory }}>
					<View style={{ ...HomeStyle.bodyHistoryHeader }}>
						<Text style={{ ...HomeStyle.title, color: "#000" }}>
							Transactions récentes
						</Text>
					</View>

					<ScrollView
						style={{ width: "100%", padding: 10, marginBottom: 20 }}
						// ref={(ref) => setScrollViewRef(ref)}
					>
						{exemplesTransactions.map((item, index) =>
							index < 5 ? (
								<TransactionComponent
									key={index}
									props={item}
									navigation={navigation}
								/>
							) : null
						)}
					</ScrollView>
				</ScrollView>
			</ScrollView>
		</SafeAreaView>
	);
};

export default Home;
