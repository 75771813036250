import { StyleSheet } from "react-native-web";

const AuthStyle = StyleSheet.create({
  Container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#FFF",
    height: "100%",
    width: "100%",
    position: "relative",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 0,
    marginBottom: 20,
    width: "100%",
    padding: 20,
    // borderWidth: 1,
  },
  stepConatiner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    marginBottom: 20,
    width: 75,
    height: 45,
    backgroundColor: "#76D5FD",
  },
  stepText: {
    fontSize: 18,
    color: "#FFF",
    fontFamily: "Poppins-Bold",
    fontWeight: "bold",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    minWidth: "100%",
    height: "auto",
    padding: 20,
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minWidth: "100%",
    height: "auto",
    // marginBottom: 10,
    padding: 10,
    // borderWidth: 1,
  },
  padContent: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  padCircle: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    width: 35,
    height: 40,
    backgroundColor: "#FFF",
    marginHorizontal: 10,
    elevation: 2,
    borderWidth: 1,
    borderColor: "#1B97F1",
  },
  padDigit: {
    fontSize: 20,
    color: "#1B97F1",
    fontFamily: "Poppins-Bold",
  },
  padButtonText: {
    fontSize: 30,
    color: "#1B97F1",
    fontFamily: "Poppins-ExtraBold",
  },
  padBody: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    // borderWidth: 1,
    width: "90%",
    height: "auto",
    flexWrap: "wrap",
    gap: 10,
  },
  padButton: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    width: 60,
    height: 63,
    backgroundColor: "#FFF",
    // marginHorizontal: 10,
    // elevation: 2,
    marginHorizontal: 20,
    margin: 10,
    borderWidth: 1,
    borderColor: "#1B97F1",
  },
  padFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    // borderWidth: 1,
    width: "90%",
    height: "auto",
    flexWrap: "wrap",
    // gap: 10,
  },
});

export default AuthStyle;
