import React from "react";
import { SafeAreaView } from "react-native";

import onboardingStyle from "../styles/onboarding.style";

const OnboardingLayout = ({ children }) => {
  return (
    <SafeAreaView style={onboardingStyle.container}>{children}</SafeAreaView>
  );
};

export default OnboardingLayout;
